import React, { Component } from 'react';
import css from './Projects.module.css';

import logo1 from './images/logo1.png';
import logo2 from './images/logo2.png';
import logo3 from './images/logo3.png';
import logo4 from './images/logo4.png';
import logo5 from './images/logo5.png';
import logo6 from './images/logo6.png';
import logo7 from './images/v.png';

class RecommendBy extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className={css.recommendByContainer}>
        <p>Recommended by</p>
        <div className={css.logoContainer}>
          <img src={logo1} alt="logo1" />
          <img src={logo7} alt="logo7"/>
          <img src={logo6} alt="logo6" />
          <img src={logo3} alt="logo3" />
          <img src={logo4} alt="logo4" />
          <img src={logo5} alt="logo5" />
        </div>
      </div>
    );
  }
}

export default RecommendBy;
