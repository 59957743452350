import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Projects.module.css';

import service from './images/service.png';
import maker from './images/maker.png';
import alteration from './images/alteration.png';

export default class Project extends Component {
  constructor(props) {
    super(props);
  }

  //   onProjectClick = e => {
  //     e.preventDefault();
  //     const { onSelectProject } = this.props;
  //     if (e.currentTarget.value !== '') {
  //       onSelectProject(e.currentTarget.value);
  //     }
  //   };

  render() {
    // const { rootClassName, className } = this.props;

    // const classes = classNames(rootClassName || css.root, className);

    return (
      <div>
        {/*<h1 className={css.heading}>Projects</h1>*/}
        <div className={css.locations}>
          <button className={css.location} value="altration">
            <div className={css.imageWrapper}>
              <div style={{ textAlign: 'center' }}>
                <img src={alteration} alt="no image" className={css.locationImage} />
              </div>
            </div>
            <div className={css.linkText}>
              <span className={css.locationName}>Choose your project</span>
              <br />
              <p className={css.para}>Alteration, Repair</p>
              <p className={css.para}>or Made-to-measure.</p>
            </div>
          </button>
          <button className={css.location} value="restoration">
            <div className={css.imageWrapper}>
              <div style={{ textAlign: 'center' }}>
                <img src={maker} alt="no image" className={css.locationImage} />
              </div>
            </div>
            <div className={css.linkText}>
              <span className={css.locationName}>Browse local Makers</span>
              <br />
              <p className={css.para}>Easily book a trusted, specialist Maker for your project.</p>
            </div>
          </button>
          <button className={css.location} value="madeToMeasure">
            <div className={css.imageWrapper}>
              <div style={{ textAlign: 'center' }}>
                <img src={service} alt="no image" className={css.locationImage} />
              </div>
            </div>
            <div className={css.linkText}>
              <span className={css.locationName}>Book a service</span>
              <br />
              <p className={css.para}>
                Have your Maker come to your home or visit their local studio.
              </p>
            </div>
          </button>
        </div>
      </div>
    );
  }
}

// Projects.defaultProps = { rootClassName: null, className: null };

// const { string } = PropTypes;

// Projects.propTypes = {
//   rootClassName: string,
//   className: string,
// };

//export default Projects;
