import React, { Component } from 'react';
import css from './Projects.module.css';
import { Button } from '../../components';

import img1 from './images/maker1.jpg';
import img2 from './images/maker2.jpg';
import img3 from './images/maker3.jpg';
import img4 from './images/maker4.jpg';
import img5 from './images/maker5.jpg';
import img6 from './images/maker6.jpg';
import img7 from './images/maker7.jpg';
import img8 from './images/maker8.jpg';


class RecentProjects extends Component {
  constructor(props) {
    super(props);
    this.sliderContainer = React.createRef();
  }

  prev = () => {
    this.sliderContainer.current.scrollLeft -= 270;
  };

  next = () => {
    this.sliderContainer.current.scrollLeft += 270;
  };

  render() {
    return (
      <div className={css.recentProjectContainer}>
        <p>Book top Makers in your neighbourhood</p>
        <div className={css.imageContainer}>
          <div id="slider-container" ref={this.sliderContainer} className={css.slider}>
            <div className={css.slide}>
              <img src={img1} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img2} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img3} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img4} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img5} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img6} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img7} alt="img1" />
            </div>
            <div className={css.slide}>
              <img src={img8} alt="img1" />
            </div>
            <div className={css.controlPrevBtn}>
              <Button type="button" onClick={() => this.prev()}>
                &lt;
              </Button>
            </div>
            <div className={css.controlNextBtn}>
              <Button type="button" onClick={() => this.next()}>
                &gt;
              </Button>
            </div>
          </div>

          <div className={css.overlay}></div>
        </div>
      </div>
    );
  }
}

export default RecentProjects;
